<template>
  <CCardBody>
    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="row">
            <!-- Country Filter -->
            <div class="col-lg-3 col-sm-6 mb-2 mb-lg-0">
              <v-select
                class="v-select-filter"
                placeholder="Country.."
                :value="$store.state.filters.country"
                :options="authCountries"
                :reduce="c => c.value"
                :searchable="false"
                @input="countryFilter"
              />
            </div>

            <!-- Year Filter -->
            <div class="col-lg-3 col-sm-6 mb-2 mb-lg-0">
              <v-select
                class="v-select-filter"
                placeholder="Year.."
                v-model="year"
                :options="years"
                :searchable="false"
                @input="getAllItems"
              />
            </div>

            <!-- Search -->
            <div class="col-md mb-1 mb-md-0 pl-md-0">
              <CInput
                class="mb-0 search"
                type="search"
                placeholder="Search for something else ..."
                v-model="search"
                @input="searchFilter"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CDataTable
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No holidays found!',
      }"
      @update:sorter-value="sorterValue"
    >
      <!-- Actions -->
      <template #actions="{ item, index }">
        <td>
          <CButton
            size="sm"
            color="info"
            variant="ghost"
            shape="pill"
            @click="editItem(item)"
          >
            <CIcon name="cil-align-left" />
          </CButton>

          <CSpinner v-if="item.deleting" size="sm" color="danger" class="ml-2" />
          <CButton
            v-else
            size="sm"
            color="danger"
            variant="ghost"
            shape="pill"
            @click="deleteItem(item, index)"
          >
            <CIcon name="cil-trash" />
          </CButton>

          <CButton
            size="sm"
            color="primary"
            variant="ghost"
            shape="pill"
            v-c-tooltip="{
              html: true,
              content: 'Duplicate'
            }"
            @click="duplicateItem(item)"
          >
            <CIcon name="cil-clone" />
          </CButton>

        </td>
      </template>

      <!-- Name -->
      <template #name="{item}">
        <td>
          <div class="d-flex align-items-center flex-gap-2">
            <CIcon :content="$options.flagSet[item.country?.flag]" size="lg" />
            <strong>{{ item.holiday_name?.name }}</strong>
          </div>
        </td>
      </template>

      <!-- Start -->
      <template #start_date="{item}">
        <td>{{ moment(item.start_date).format('LL') }}</td>
      </template>

      <!-- End -->
      <template #end_date="{item}">
        <td>{{ item.end_date ? moment(item.end_date).format('LL') : '' }}</td>
      </template>
    </CDataTable>

    <!-- Actions -->
    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>
        </div>
      </CCardBody>
    </CCard>

    <holiday-item :holiday.sync="selectedItem" @updated="getAllItems" />
    <mc-spinner :opacity="0.8" v-show="loading" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
  </CCardBody>
</template>

<script>
import _debounce from 'lodash/debounce';
import { flagSet } from '@coreui/icons'
import HolidayItem from './HolidayItem'

export default {
  name: "BackendTable",
  flagSet,
  components: {
    'holiday-item': HolidayItem
  },
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "actions", label: "", sorter: false, _style: "min-width: 120px; width: 120px;" },
        { key: "name", label: "Name", _style: "min-width: 250px;" },
        { key: "start_date", label: "Start", sorter: true, _style: "min-width: 140px; width: 300px;" },
        { key: "end_date", label: "End", _style: "min-width: 140px; width: 300px;" },
      ],

      total: 0,
      orderBy: null,
      direction: null,
      search: null,
      year: null,

      selectedItem: null,
      loading: false,

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,
    };
  },

  computed: {
    years() {
      const currentYear = new Date().getFullYear() - 10;
      return Array.from({ length: 20 }, (_, index) => currentYear + index);
    }
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getAllItems();
    }, 1000);
  },

  mounted() {
    this.getAllItems();

    window.onscroll = () => {
      this.mcSpinnerMarginTop = document.documentElement.scrollTop;
    }
  },

  methods: {
    getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.HOLIDAYS.GET_ALL),
        params = {
          'order-by': this.orderBy,
          direction: this.direction,
          country: this.$store.state.filters.country,
          search: this.search,
          year: this.year,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          this.total = items.length;
          this.loadedItems = items.map((el) => {
            return {
              ...el,
              country: this.authCountries.find((c) => c.value === el.country_id),
              deleting: false,
            };
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.direction = item.column ? (item.asc ? "asc" : "desc") : null;
      this.getAllItems();
    },
    editItem(item) {
      this.selectedItem = item;
      this.$store.commit('set', ['holidayItemAsideShow', true]);
    },
    duplicateItem(item) {
      const startDateObject = new Date(item.start_date);
      const endDateObject = new Date(item.end_date);

      this.selectedItem = {
        ...item,
        id: null,
        start_date: startDateObject.setFullYear(startDateObject.getFullYear() + 1),
        end_date: item.end_date ? endDateObject.setFullYear(endDateObject.getFullYear() + 1) : null
      };

      this.$store.commit('set', ['holidayItemAsideShow', true]);
    },
    async deleteItem(item, index) {
      const confirm = await this.mSwal.fire({
        title: "Are you sure you want to delete?",
        text: "The holiday will be deleted!",
        icon: "warning",
      });

      if (confirm.isConfirmed) {
        const deleteUrl = this.$backend.HOLIDAYS.DELETE.replace("{id}", item.id);
        item.deleting = true;

        try {
          const { data } = await this.$axios.delete(deleteUrl);
          index !== -1 && this.$delete(this.loadedItems, index);
          this.$toast.success(data.message);
        } catch (error) {
          item.deleting = false;

          if (error.response) {
            this.$toast.error(`${error.response.data.message}`);
          } else if (error.request) {
            this.$toast.error('No response received from the server.');
          } else {
            this.$toast.error('An unexpected error occurred.');
          }
        } finally {
          this.$forceUpdate();
        }
      }
    },

    // Filters
    countryFilter(value) {
      this.$store.commit("setCountryFilter", value);
      this.getAllItems();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.$store.commit("setCountryFilter", null);
        this.search = "";
        this.year = null;
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },
  },
};
</script>

<style>
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}

.search input {
  font-size: 1rem !important;
}
</style>
