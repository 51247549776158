<template>
  <CSidebar
    aside
    :show="$store.state.holidayItemAsideShow"
    @update:show="(val) => $store.commit('set', ['holidayItemAsideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'holidayItemAsideShow')" />

    <CListGroup class="scrollable list-group-accent mt-5">
      <CListGroupItem
        class="mb-0 list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
        {{ holiday?.id ? 'Edit' : 'Add' }} Holiday
      </CListGroupItem>

      <CListGroupItem class="list-group-item-divider list-group-item-accent-primary">
        <CForm @submit.prevent="submit" class="holiday-item">
          <CSelect
            label="Country:"
            :lazy="false"
            :options="countries"
            :value.sync="$v.form.country_id.$model"
          />

          <CInput
            label="Name:"
            placeholder="Name"
            :value.sync="$v.form.name.$model"
            :isValid="checkIfValid('name')"
          />

          <div class="form-group">
            <label class="d-block">Start Date:</label>
            <div class="d-flex flex-gap-2">
              <CSelect
                class="mb-0"
                :options="years"
                :value.sync="startDate.year"
              />
              <CSelect
                class="mb-0"
                :options="months"
                :value.sync="startDate.month"
              />
              <CSelect
                class="mb-0"
                :options="daysInStartMonth"
                :value.sync="startDate.day"
              />
            </div>
          </div>

          <div class="d-flex align-items-center flex-gap-1 mb-3">
            <CSwitch
              labelOn="YES"
              labelOff="NO"
              color="success"
              size="sm"
              variant="opposite"
              :checked.sync="hasEndDate"
            />
            <small>Have an end date?</small>
          </div>

          <div class="form-group" v-if="hasEndDate">
            <label v-if="$v.form.end_date.$invalid" class="d-block alert alert-danger p-2">⚠ End Date should be greater than the start date!</label>
            <label v-else class="d-block">End Date:</label>

            <div class="d-flex flex-gap-2">
              <CSelect
                class="mb-0"
                :options="years"
                :value.sync="endDate.year"
              />
              <CSelect
                class="mb-0"
                :options="months"
                :value.sync="endDate.month"
              />
              <CSelect
                class="mb-0"
                :options="daysInEndMonth"
                :value.sync="endDate.day"
              />
            </div>
          </div>
        </CForm>
      </CListGroupItem>

      <CListGroupItem class="sticky-bottom list-group-item-accent-primary bg-white">
        <CButton color="primary" @click="submit">
          <CIcon name="cil-send" /> Submit
        </CButton>
      </CListGroupItem>
    </CListGroup>

    <CElementCover :opacity="0.4" v-show="submitted" />
  </CSidebar>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

export default {
  name: "HolidayItem",
  props: {
    holiday: { default: null, type: Object },
  },
  data() {
    return {
      form: { },

      submitted: false,
      errorAlertMessage: '',

      startDate: { day: 1, month: 1, year: null },
      endDate: { day: 1, month: 1, year: null },
      hasEndDate: false,
      months: [
        { value: 1, label: "January" },
        { value: 2, label: "February" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" }
      ]
    };
  },

  mixins: [validationMixin],
  validations: {
    form: {
      country_id: {},
      name: { required },
      start_date: {},
      end_date: {
        minDate: function () {
          return this.hasEndDate ? new Date(this.form.end_date) > new Date(this.form.start_date) : true;
        }
      },
    }
  },

  watch: {
    async reloadParams() {
      if (this.$store.state.holidayItemAsideShow) {
        this.form = {
          id: this.holiday?.id,
          country_id: this.holiday?.country_id || 0,
          name: this.holiday?.holiday_name?.name || null,
          start_date: this.holiday?.start_date || null,
          end_date: this.holiday?.end_date || null,
        };

        this.hasEndDate = !!(this.form.end_date);

        const startDateObject = this.form.start_date ? new Date(this.form.start_date) : new Date();
        const endDateObject = this.form.end_date ? new Date(this.form.end_date) : startDateObject;

        this.startDate = {
          day: startDateObject.getDate(),
          month: startDateObject.getMonth() + 1,
          year: startDateObject.getFullYear(),
        };
        this.endDate = {
          day: endDateObject.getDate(),
          month: endDateObject.getMonth() + 1,
          year: endDateObject.getFullYear(),
        };
      } else {
        this.$emit("update:holiday", null);
      }
    },

    startDate: {
      deep: true,
      handler() {
        this.form.start_date = `${this.startDate.year}-${this.startDate.month}-${this.startDate.day}`;
      },
    },
    endDate: {
      deep: true,
      handler() {
        this.form.end_date = this.hasEndDate
          ? `${this.endDate.year}-${this.endDate.month}-${this.endDate.day}`
          : null;
      },
    },
    hasEndDate(value) {
      if (value) {
        if (!this.form.end_date) {
          const endDateObject = new Date(this.form.start_date);
          endDateObject.setDate(endDateObject.getDate() + 1);

          this.endDate = {
            day: endDateObject.getDate(),
            month: endDateObject.getMonth() + 1,
            year: endDateObject.getFullYear(),
          };
        }
      } else {
        this.form.end_date = null;
      }
    }
  },

  computed: {
    ...mapGetters(["errors"]),
    reloadParams() { return [this.$store.state.holidayItemAsideShow] },
    countries() {
      return [
        { value: 0, label: "All countries", code: null, iso2: null, flag: null },
        ...this.authCountries
      ]
    },
    daysInStartMonth() {
      const month = this.startDate.month;
      const year = this.startDate.year;
      const lastDay = new Date(year, month, 0).getDate();

      return Array.from({ length: lastDay }, (_, index) => index + 1);
    },
    daysInEndMonth() {
      const month = this.endDate.month;
      const year = this.endDate.year;
      const lastDay = new Date(year, month, 0).getDate();

      return Array.from({ length: lastDay }, (_, index) => index + 1);
    },
    years() {
      const currentYear = new Date().getFullYear();
      return Array.from({ length: 10 }, (_, index) => currentYear + index);
    }
  },

  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },
    validate() {
      this.$v.$touch()
    },

    async submit() {
      this.form.country_id ||= null; // 0 ise null yap

      if (this.$v.form.$invalid) {
        this.$v.$touch()
        return;
      }

      if (this.holiday?.id) {
        await this.update()
      } else {
        await this.store()
      }
    },
    async update() {
      try {
        this.submitted = true;

        const { data } = await this.$axios.put(this.$backend.HOLIDAYS.UPDATE.replace("{id}", this.holiday.id), this.form);

        this.$emit("updated");
        this.$store.commit('set', ['holidayItemAsideShow', false]);
        this.$toast.success(`${data.message}`);
      } catch (error) {
        this.$toast.error(typeof this.errors === 'object'
          ? Object.values(this.errors).join()
          : this.errors
        );
      } finally {
        this.submitted = false;
      }
    },
    async store() {
      try {
        this.submitted = true;

        const { data } = await this.$axios.post(this.$backend.HOLIDAYS.STORE, this.form);

        this.$emit("updated");
        this.$store.commit('set', ['holidayItemAsideShow', false]);
        this.$toast.success(`${data.message}`);
      } catch (error) {
        this.$toast.error(typeof this.errors === 'object'
          ? Object.values(this.errors).join()
          : this.errors
        );
      } finally {
        this.submitted = false;
      }
    },
  }
};
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  height: 100%;
}
</style>

<style>
form.holiday-item .form-control {
  font-size: 1.025rem;
  color: #9b0a0a;
  font-weight: 500;
  padding: 0.375rem 0.375rem;
}
</style>
