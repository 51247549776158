var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCardBody',[_c('CCard',{staticClass:"filters",attrs:{"accent-color":"warning"}},[_c('CCardHeader',{staticClass:"text-warning shadow-none card-header",attrs:{"role":"button"},on:{"click":_vm.resetFilters}},[_c('strong',{staticClass:"m-0"},[_c('CIcon',{attrs:{"name":`cil-filter${_vm.$store.state.filterShow ? '-x' : ''}`}}),_vm._v(" Filters")],1),_c('div',{staticClass:"card-header-actions"},[_c('CIcon',{attrs:{"name":`cil-chevron-${_vm.$store.state.filterShow ? 'bottom' : 'top'}`}})],1)]),_c('CCollapse',{attrs:{"show":_vm.$store.state.filterShow}},[_c('CCardBody',{staticClass:"p-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-sm-6 mb-2 mb-lg-0"},[_c('v-select',{staticClass:"v-select-filter",attrs:{"placeholder":"Country..","value":_vm.$store.state.filters.country,"options":_vm.authCountries,"reduce":c => c.value,"searchable":false},on:{"input":_vm.countryFilter}})],1),_c('div',{staticClass:"col-lg-3 col-sm-6 mb-2 mb-lg-0"},[_c('v-select',{staticClass:"v-select-filter",attrs:{"placeholder":"Year..","options":_vm.years,"searchable":false},on:{"input":_vm.getAllItems},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),_c('div',{staticClass:"col-md mb-1 mb-md-0 pl-md-0"},[_c('CInput',{staticClass:"mb-0 search",attrs:{"type":"search","placeholder":"Search for something else ..."},on:{"input":_vm.searchFilter},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])])],1)],1),_c('CDataTable',{attrs:{"striped":"","hover":"","items":_vm.loadedItems,"fields":_vm.fields,"sorter":{ external: true, resetable: true },"loading":_vm.loading,"noItemsView":{
      noResults: 'No filtering results are available!',
      noItems: 'No holidays found!',
    }},on:{"update:sorter-value":_vm.sorterValue},scopedSlots:_vm._u([{key:"actions",fn:function({ item, index }){return [_c('td',[_c('CButton',{attrs:{"size":"sm","color":"info","variant":"ghost","shape":"pill"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('CIcon',{attrs:{"name":"cil-align-left"}})],1),(item.deleting)?_c('CSpinner',{staticClass:"ml-2",attrs:{"size":"sm","color":"danger"}}):_c('CButton',{attrs:{"size":"sm","color":"danger","variant":"ghost","shape":"pill"},on:{"click":function($event){return _vm.deleteItem(item, index)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            html: true,
            content: 'Duplicate'
          }),expression:"{\n            html: true,\n            content: 'Duplicate'\n          }"}],attrs:{"size":"sm","color":"primary","variant":"ghost","shape":"pill"},on:{"click":function($event){return _vm.duplicateItem(item)}}},[_c('CIcon',{attrs:{"name":"cil-clone"}})],1)],1)]}},{key:"name",fn:function({item}){return [_c('td',[_c('div',{staticClass:"d-flex align-items-center flex-gap-2"},[_c('CIcon',{attrs:{"content":_vm.$options.flagSet[item.country?.flag],"size":"lg"}}),_c('strong',[_vm._v(_vm._s(item.holiday_name?.name))])],1)])]}},{key:"start_date",fn:function({item}){return [_c('td',[_vm._v(_vm._s(_vm.moment(item.start_date).format('LL')))])]}},{key:"end_date",fn:function({item}){return [_c('td',[_vm._v(_vm._s(item.end_date ? _vm.moment(item.end_date).format('LL') : ''))])]}}])}),_c('CCard',{staticClass:"actions sticky-bottom"},[_c('CCardBody',{staticClass:"p-2"},[_c('div',{staticClass:"d-flex flex-wrap align-items-center",staticStyle:{"gap":"0.75rem"}},[_c('div',[_c('h5',{staticClass:"mt-1"},[_vm._v("Total: "),_c('span',{staticClass:"d-inline count bg-primary pb-1"},[_vm._v(_vm._s(_vm.total))])])])])])],1),_c('holiday-item',{attrs:{"holiday":_vm.selectedItem},on:{"update:holiday":function($event){_vm.selectedItem=$event},"updated":_vm.getAllItems}}),_c('mc-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"opacity":0.8,"mtop":(32 + _vm.mcSpinnerMarginTop) + 'px'}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }